import React from 'react';
import { navigate } from 'gatsby'
import { Box, Card, CardHeader, CardBody, Heading,  Image, Paragraph } from 'grommet';

export default function SidebarIA() {

  const onClickButton_IAHome = () => {
    navigate ('/internal-auditor')
  }
  return (
    <Box pad={{top: '10px'}} direction="column" justify="between" gap="medium" style={{maxWidth: '320px', width: '100%', height: 'max-content'}}>
      <Card background="light-1" style={{borderRadius: '.5em', overflow: 'visible', boxShadow: 'none'}}>
      <CardHeader gap="small" justify="start">
        <Box style={{border: '4px solid #F8F8F8', borderRadius: '50%', margin: '-30px 0 0 20px', maxWidth: '110px', width: '100%'}}><Image fill="horizontal" src="https://images.ctfassets.net/fwgo2d14oeir/3LbgTGDRPpJUxBmOvzpwHs/554b4317d067c2564400ff2237d9826e/ia-avatar-2.png" style={{borderRadius: '50%'}} onClick={onClickButton_IAHome} /></Box>
        <Heading level={4} style={{paddingTop: '10px'}}>Internal Auditor</Heading>
        </CardHeader>
        <CardBody pad="medium">
          <Paragraph style={{fontSize: '14px', lineHeight: '1.4', marginBottom: '20px'}}>Internal auditors are responsible for ensuring that internal controls and risk management processes are effective. They conduct audits to identify areas for improvement, and identify potential fraud, waste, and abuse and work to prevent these risks.</Paragraph>
          <Heading level={5} style={{marginBottom: '10px'}}>Challenges</Heading>
          <ul style={{fontSize: '14px', lineHeight: '1.5', marginBottom: '20px', padding: '0 0 0 15px'}}>
            <li>Facing resistance for challenging established ways of working</li>
            <li>Limited resources to conduct organization-wide audits and assurance​</li>
            <li>Staying current with emerging risks and approaches to effective audit</li>
          </ul>
        </CardBody>
      </Card>
      <Paragraph style={{fontSize: '18px', lineHeight: '1.5', fontWeight: '700', padding: '0 20px'}}>To learn more, reach out to your PwC Sponsor.</Paragraph>
    </Box>
  );
}